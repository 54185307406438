import React from 'react'
import { usePageThemeContext } from 'src/lib/pageThemeContext'

const MobileIcon = () => {
  const { accentColor } = usePageThemeContext()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="89"
      fill="none"
      viewBox="0 0 89 89"
    >
      <path fill="none" d="M0 0H88V88H0z" transform="translate(.5 .5)"></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.898"
        d="M28.5 75.5a4 4 0 01-4-4v-53a4 4 0 014-4h33a4 4 0 014 4v53a4 4 0 01-4 4h-33z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.898"
        d="M24.5 20.5v-2a4 4 0 014-4h33a4 4 0 014 4v2h-41z"
      ></path>
      <path
        fill={accentColor}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M57.087 54.14l-21.116 2.728c-2.13.278-4.079-1.244-4.354-3.376l-1.572-12.36c-.275-2.133 1.247-4.085 3.377-4.363l21.104-2.7c2.13-.277 4.05 1.231 4.326 3.364l1.572 12.36c.287 2.104-1.206 4.07-3.337 4.347z"
      ></path>
      <path stroke="#fff" d="M39.95 45.442l4.437 3.258 5.75-7.828"></path>
      <circle cx="39.5" cy="17.5" r="1" fill="#000"></circle>
      <rect width="9" height="2" x="41.5" y="16.5" fill="#000" rx="1"></rect>
    </svg>
  )
}

export default MobileIcon
