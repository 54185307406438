import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { Box } from '@chatterbug/aaron'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import PageContainer from 'src/components/PageContainer/PageContainer'
import GiftSteps from 'src/components/GiftSteps/GiftSteps'
import background from 'src/sections/Streams/background.svg'
import { PageAccentVariant } from 'src/types'

import GiftVouchers from 'src/sections/GiftVouchers/GiftVouchers'
import { useLocale } from 'src/lib/I18nContext'
import { PageContext, Product } from 'src/types'
import { useStreamsGiftSteps } from 'src/components/GiftSteps/useStreamsGiftSteps'
import { StreamsThemeProvider } from 'src/lib/pageThemeContext'
import StreamsFeatures from 'src/sections/Streams/subcomponents/StreamsFeatures/StreamsFeatures'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import StreamsHeader from 'src/components/StreamsHeader/StreamsHeader'
import GiftsHeroContent from 'src/sections/GiftsHero/subcomponents/GiftHeroContent/GiftsHeroContent'
import GiftVoucherGradientBackground from 'src/components/GradientBackground/GiftVoucherGradientBackground'

const Page: React.FC<PageProps<unknown, PageContext>> = ({ pageContext }) => {
  const t = useTranslate()
  const locale = useLocale()
  const giftSteps = useStreamsGiftSteps()

  return (
    <StreamsThemeProvider>
      <Layout pageContext={pageContext} hideSiteHeader>
        <Head
          // TODO: is this the title they want for the page?
          title={t('Streams Gift Vouchers')}
          description={t('streams_gifts_hero.sub_header')}
          socialImage={`/assets/global/og-images/gifts/streams/${locale}.jpg`}
          pageContext={pageContext}
        />
        <GiftVoucherGradientBackground
          variant={PageAccentVariant.blue}
          sx={{ overflow: 'hidden' }}
        >
          <PageContainer>
            <Box pb={140}>
              <StreamsHeader canonicalPath={pageContext.canonicalPath} />
              <GiftsHeroContent
                header={t('streams_gifts_hero.main_header')}
                subheader={t('streams_gifts_hero.sub_header')}
                cta={t('streams_gifts_hero.cta')}
                product={Product.streams}
              />
            </Box>
          </PageContainer>
        </GiftVoucherGradientBackground>
        <Box mt={-50}>
          <FullWidthBackground image={background} width={1439} height={741}>
            <PageContainer>
              <Box pt={120}>
                <GiftSteps giftSteps={giftSteps} />
              </Box>
            </PageContainer>
            <Box as="section">
              <StreamsFeatures />
            </Box>
          </FullWidthBackground>
        </Box>
        {/* negative border stops a small line appearing between the sections */}
        <Box bg="white" mt={-1}>
          <GiftVouchers product={Product.streams} />
        </Box>
      </Layout>
    </StreamsThemeProvider>
  )
}

export default Page
