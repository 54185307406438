import { useTranslate } from 'react-polyglot'
import { GiftStepsProps } from './GiftSteps'
import ChooseIcon from './subcomponents/GiftStep/assets/ChooseIcon'
import MobileIcon from './subcomponents/GiftStep/assets/MobileIcon'
import ShareIcon from './subcomponents/GiftStep/assets/ShareIcon'

export const useStreamsGiftSteps = () => {
  const t = useTranslate()

  const giftSteps: GiftStepsProps['giftSteps'] = [
    {
      type: 'choose',
      title: t('gift_step_streams.choose.title'),
      description: t('gift_step_streams.choose.description'),
      icon: ChooseIcon,
    },
    {
      type: 'share',
      title: t('gift_step_streams.share.title'),
      description: t('gift_step_streams.share.description'),
      icon: ShareIcon,
    },
    {
      type: 'start',
      title: t('gift_step_streams.start.title'),
      description: t('gift_step_streams.start.description'),
      icon: MobileIcon,
    },
  ]

  return giftSteps
}
