import React from 'react'
import { CSSProperties } from 'styled-components'
import { Box } from '@chatterbug/aaron'

import { PageAccentVariant } from 'src/types'

// png looks the same across all browsers, while svg does not
import blueBackground from './assets/blue.png'
import pinkBackground from './assets/pink.png'

export const BACKGROUND_CIRCLE_SIZE = 2265

export const makeCentered = () => ({
  left: '50%',
  marginLeft: -BACKGROUND_CIRCLE_SIZE / 2, // to center horizontally
})

export type Props = {
  sx?: CSSProperties
  variant: PageAccentVariant
}

const GiftVoucherGradientBackground: React.FC<Props> = ({
  sx,
  variant,
  children,
}) => {
  return (
    <Box
      data-testid="background"
      sx={{
        background: `url(${
          variant === PageAccentVariant.blue ? blueBackground : pinkBackground
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        // width: BACKGROUND_CIRCLE_SIZE,
        // height: BACKGROUND_CIRCLE_SIZE,
        ...(sx || {}),
      }}
    >
      {children}
    </Box>
  )
}

export default GiftVoucherGradientBackground
